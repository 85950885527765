import axios from "axios";

export async function getTemplate(id: string | number, token: string) {
  let data: any = null;

  // let headersList = {
  //   Accept: "*/*",
  //   "Content-Type": "application/json",
  // };
  // let bodyContent = JSON.stringify({
  //   id: "paul.gampel@gmail.com",
  //   password: "111111",
  // });
  // let response = await axios.request({
  //   url: "https://stage.elepay.com.au/api/jwt_login",
  //   method: "POST",
  //   headers: headersList,
  //   data: bodyContent,
  // });
  // console.log(response.data);

  let headersList = {
    Accept: "*/*",
    Authorization: `Bearer ${token}`,
  };
  let response = await fetch(
    `https://stage.tail4a5a2.ts.net/message/re_template?id=${id}`,
    {
      method: "GET",
      headers: headersList,
    }
  );

  data = await response.text();
  let jsondata = JSON.parse(data);
  let dataAdapter = {
    article_id: 0,
    user_id: 0,
    category_id: 0,
    tags: { tag_id: 0 }[""],
    picture: "",
    subject: `${jsondata.subject}`,
    summary: "",
    secret: 0,
    readcount: 0,
    updated_at: 0,
    created_at: 0,
    level: 0,
    emailbody: `${jsondata.emailmjml.replace(/\n/g, "")}`,
  };

  return dataAdapter;
}
