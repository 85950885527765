import { article, IArticle } from "../services/article";
import createSliceState from "./common/createSliceState";
import { Message } from "@arco-design/web-react";
import { history } from "../utils/history";
import { emailToImage } from "../utils/emailToImage";
import {
  BasicType,
  BlockManager,
  JsonToMjml,
  MjmlToJson,
} from "easy-email-core";
import { IEmailTemplate } from "easy-email-editor";
import { getTemplate } from "../config/getTemplate";
import mjml from "mjml-browser";

export function getAdaptor(data: IArticle): IEmailTemplate {
  const content = MjmlToJson(
    JSON.stringify(data.emailbody).replace(/\\"/g, '"').replace('"', "")
  );
  return {
    ...data,
    content,
    subject: data.subject,
    subTitle: data.summary,
  };
}

export default createSliceState({
  name: "template",
  initialState: null as IEmailTemplate | null,
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
  effects: {
    fetchById: async (
      state,
      {
        id,
        token,
      }: {
        id: number;
        token: string;
      }
    ) => {
      try {
        let data = await getTemplate(id, token);
        if (!data) {
          data = await article.getArticle(id);
        }
        return getAdaptor(data);
      } catch (error) {
        history.replace("/");
        throw error;
      }
    },
    fetchDefaultTemplate: async (state) => {
      return {
        subject: "Welcome to Easy-email",
        subTitle: "Nice to meet you!",
        content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
      } as IEmailTemplate;
    },
    create: async (
      state,
      payload: {
        template: IEmailTemplate;
        success: (id: number, data: IEmailTemplate) => void;
      }
    ) => {
      const picture = await emailToImage(payload.template.content);
      const data = await article.addArticle({
        picture,
        summary: payload.template.subTitle,
        title: payload.template.subject,
        content: JSON.stringify(payload.template.content),
      });
      payload.success(data.article_id, getAdaptor(data));
      return { ...data, ...payload.template };
    },
    duplicate: async (
      state,
      payload: {
        article: {
          article_id: number;
        };
        success: (id: number) => void;
      }
    ) => {
      const source = await article.getArticle(payload.article.article_id);
      const data = await article.addArticle({
        title: source.subject,
        content: source.emailbody,
        picture: source.picture,
        summary: source.summary,
      });
      payload.success(data.article_id);
    },
    updateById: async (
      state,
      payload: {
        id: number;
        token: string;
        template: IEmailTemplate;
        success: (templateId: number) => void;
      }
    ) => {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${payload.token}`,
          "Content-Type": "application/json",
        };

        await fetch(`https://stage.tail4a5a2.ts.net/message/re_template`, {
          method: "POST",
          headers: headersList,
          body: JSON.stringify({
            id: payload.id,
            emailmjml: JsonToMjml({
              data: payload.template.content,
              mode: "production",
              context: payload.template.content,
            }),
            emailbody: mjml(
              JsonToMjml({
                data: payload.template.content,
                mode: "production",
                context: payload.template.content,
              }),
              {
                validationLevel: "soft",
              }
            ).html,
          }),
        });
        payload.success(payload.id);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          throw {
            message: "Cannot change the default template",
          };
        }
      }
    },
    removeById: async (state, payload: { id: number; success: () => void }) => {
      try {
        await article.deleteArticle(payload.id);
        payload.success();
        Message.success("Removed success.");
      } catch (error: any) {
        if (error?.response?.status === 404) {
          throw {
            message: "Cannot delete the default template",
          };
        }
      }
    },
  },
});
