import "./axios.config";
import { common } from "./common";
import { article } from "./article";
import { user } from "./user";

const services = {
  common,
  article,
  user,
};

export default services;
